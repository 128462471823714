import React, { createRef, useContext, useEffect, useState } from 'react'
import gql from 'graphql-tag'
import { navigate } from 'gatsby'
import { Mutation } from 'react-apollo'
import StoreContext from '../../context/StoreContext'
import {
  BodyText,
  Button,
  FormInput,
  FormLabel,
  LargerHeading,
} from '../../styles'
import Navigation from '../../components/Navigation'
import styled from 'styled-components'
import media from '../../styles/media'

const ResetCon = styled.div`
  margin: var(--gutter-m) var(--gutter-s);
  ${media.laptop`
  margin: var(--gutter-m);
    .formFields {
      display: flex;
      .field {
        width: 50%;
      }
    }
  `}
`

const CUSTOMER_RESET = gql`
  mutation customerReset($id: ID!, $input: CustomerResetInput!) {
    customerReset(id: $id, input: $input) {
      userErrors {
        field
        message
      }
      customer {
        id
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
    }
  }
`

const CUSTOMER_LOGOUT = gql`
  mutation customerAccessTokenDelete($customerAccessToken: String!) {
    customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
      deletedAccessToken
      deletedCustomerAccessTokenId
      userErrors {
        field
        message
      }
    }
  }
`

const ResetPage = () => {
  const { setValue, customerAccessToken } = useContext(StoreContext)
  let buttonRef = createRef()

  const [newPassword, setNewPassword] = useState('')
  const [customerId, setCustomerId] = useState('')
  const [resetToken, setResetToken] = useState('')

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(document.location.search.substring(1))

      setResetToken(params.get('token'))
      setCustomerId(window.btoa(`gid://shopify/Customer/${params.get('id')}`))
    }
  }, [])

  const handleCustomerAccessToken = value => {
    setValue(value)
  }

  return (
    <>
      <Navigation />
      <ResetCon>
        <LargerHeading className="bottomSpace">
          Reset account password
        </LargerHeading>
        <BodyText className="black">Enter a new password</BodyText>
        <br />
        <br />
        <Mutation mutation={CUSTOMER_RESET}>
          {resetPassword => {
            return (
              <>
                <div className="formFields">
                  <div className="field">
                    <FormLabel className="label" htmlFor="loginEmail">
                      Password
                    </FormLabel>
                    <div className="control">
                      <FormInput
                        className="input full"
                        type="text"
                        id="password"
                        onChange={e => setNewPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <FormLabel className="label" htmlFor="loginPassword">
                      Confirm Password
                    </FormLabel>
                    <div className="control">
                      <FormInput
                        className="input full"
                        type="text"
                        id="passwordConfirm"
                      />
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <Button
                  className="form-button bold"
                  onClick={() => {
                    resetPassword({
                      variables: {
                        id: customerId,
                        input: {
                          resetToken: resetToken,
                          password: newPassword,
                        },
                      },
                    }).then(res => {
                      buttonRef.click()
                      if (res.data.customerReset.customerAccessToken) {
                        handleCustomerAccessToken(
                          res.data.customerReset.customerAccessToken
                        )
                        navigate('/../account')
                      } else {
                        // console.log(res.data.customerReset.userErrors)
                        // const errors = parseErrors(
                        //   res.data.customerReset.userErrors
                        // )
                        // actions.setErrors(errors)
                      }
                    })
                  }}
                >
                  Reset Password
                </Button>
              </>
            )
          }}
        </Mutation>
      </ResetCon>
      <Mutation
        mutation={CUSTOMER_LOGOUT}
        onCompleted={data => {
          if (data.customerAccessTokenDelete.userErrors.length) return
          setValue({
            customerAccessToken: '',
          })
          navigate('/account/login')
        }}
      >
        {customerLogout => {
          return (
            <Button
              className="hidden"
              ref={component => (buttonRef = component)}
              onClick={e => {
                e.preventDefault()
                customerLogout({
                  variables: {
                    customerAccessToken: customerAccessToken.accessToken,
                  },
                })
              }}
            >
              Log out
            </Button>
          )
        }}
      </Mutation>
    </>
  )
}

export default ResetPage
